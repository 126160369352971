import {Component, Input, OnInit} from "@angular/core";
import {EmployeeService, PublicationTypeDirectSend} from "../../../../api/core";
import {PermissionService} from "../../../../services/permission.service";
import {EProtectedActions} from "../../../../util/protected-actions";
import {first} from "rxjs/operators";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";

@Component({
  selector: 'app-employee-publication-types',
  templateUrl: './employee-publication-types.component.html'
})
export class EmployeePublicationTypesComponent implements OnInit{

  @Input()
  employeeId: number;

  publicationTypes: PublicationTypeDirectSend[] = [];
  canEdit: boolean = false;
  showNote = false;

  constructor(
    private readonly employeeService: EmployeeService,
    private readonly permissionService: PermissionService,
  ) {
    this.canEdit = this.permissionService.hasAnyPermission(EProtectedActions.editEmployee);
  }

  ngOnInit() {
    this.employeeService.getPublicationTypes(this.employeeId)
      .pipe(first())
      .subscribe(data => {
        this.publicationTypes = this.filter(data.publicationTypes);
        this.showNote = data.showNote;
      });
  }

  private filter(list: PublicationTypeDirectSend[]): PublicationTypeDirectSend[] {
    return list.filter(pt => pt.directSend || !pt.type.closed);
  }

  onToggleChanged(ev: MatSlideToggleChange, pt: PublicationTypeDirectSend) {
    const directSend = ev.checked;
    this.employeeService.setPublicationTypes(this.employeeId, [{
      ...pt,
      directSend,
    }])
      .pipe(first())
      .subscribe(types => {
        this.publicationTypes = this.filter(types);
      });
  }
}
