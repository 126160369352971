<app-card>
  <div card-header>
    <span>{{ 'directDelivery' | translate }}</span>
  </div>
  <div card-body>
    <div *ngFor="let item of publicationTypes; let i = index" >
      <mat-slide-toggle
        color="primary"
        [checked]="item.directSend"
        (change)="onToggleChanged($event, item)"
        [disabled]="!canEdit"
      >
        {{item.type.name}}
      </mat-slide-toggle>
    </div>
    <div id="explanation" *ngIf="showNote">
      <span>{{ 'directDeliveryExplanationEmployee' | translate }}</span>
    </div>
  </div>
</app-card>
